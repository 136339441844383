import { NgModule } from '@angular/core';
import { ChildProcessService } from './src/childProcessService';
import { ChildProcessServiceRef } from './src/childProcessServiceRef';
export * from './src/childProcessService';
export * from './src/childProcessServiceRef';
var NgxChildProcessModule = (function () {
    function NgxChildProcessModule() {
    }
    return NgxChildProcessModule;
}());
export { NgxChildProcessModule };
NgxChildProcessModule.decorators = [
    { type: NgModule, args: [{
                declarations: [],
                exports: [],
                providers: [{ provide: ChildProcessService, useClass: ChildProcessServiceRef }]
            },] },
];
/** @nocollapse */
NgxChildProcessModule.ctorParameters = function () { return []; };
